import React from "react";
import IconAddmoney from "../../assets/images/IconAddmoney";
import IconAEPS from '../../assets/images/IconAEPS';
import IconBillRecharge from "../../assets/images/IconBillRecharge";
import IconBroadband from "../../assets/images/IconBroadband";
import IconDTH from "../../assets/images/IconDTH";
import IconExploreMore from "../../assets/images/IconExploreMore";
import IconMobileRecharge from "../../assets/images/IconMobileRecharge";
import IconSendmoney from "../../assets/images/IconSendmoney";
import IconCredit from '../../assets/images/IconCredit';
import IconWater from "../../assets/images/IconWater";

const iconMap = {
  IconAEPS: <IconAEPS color={"#e3871e"} />,
  IconMobileRecharge: <IconMobileRecharge color={"#f7c248"} color_2={"#FF6600"} />,
  IconBillRecharge: <IconBillRecharge color={"#4C369C"} color_2={"#4AD728"} />,
  IconBroadband: <IconBroadband color={"#4C369C"} color_2={"#4AD728"} />,
  IconDTH: <IconDTH color={"#f7c248"} />,
  IconSendmoney: <IconSendmoney color={"#f7c248"} color_2={"#FF6600"} />,
  IconAddmoney: <IconAddmoney color={"#f7c248"} color_2={"#FF6600"} />,
  IconCreditCard: <IconCredit color={"#f7c248"} color_2={"#FF6600"} />,
  IconWater: <IconWater color={"#4C369C"} color_2={"#4AD728"} />,
  IconExploreMore: <IconExploreMore color={"#4C369C"} color_2={"#4AD728"} />
}

const TabNavItem = ({ id, title, isActive, setActiveTab, icon = "" }) => {
 
 
return (
   <div onClick={() =>setActiveTab(id)} className={isActive ? "nav-link active" : "nav-link"}>
    <div className="pic flex items-center gap-10">
		{iconMap?.[icon]}
    <span>{title}</span>
	</div>
		
   </div>
 );
};
export default TabNavItem;